<template>
  <div class="system">
    <img src="~@/assets/error/404.png"/>
    <div class="title">
      <h2>页面不存在...</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExceptionPage404'
}
</script>

<style lang="scss" scoped>

.system {
  width: 1100px;
  margin: 0 auto;
  text-align: center;
  padding-top: 140px;

  img {
    text-align: center;
    width: 500px;
  }

  h2 {
    color: #fff;
    font-size: 36px;
    letter-spacing: 4px;
    text-align: center;
    line-height: 120px;
  }

  h4 {
    color: #fff;
    font-size: 18px;
    /* font-weight: bold; */
    line-height: 38px;
    text-align: center;
  }
}
</style>